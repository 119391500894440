@import "config";

/* FONTS */
@font-face
{
    font-family: ltr-primary;
    src: url('../fonts/ltr_primary.ttf')  format('truetype');
}
@font-face
{
    font-family: ltr-secondary;
    src: url('../fonts/ltr_secondary.ttf')  format('truetype');
}
@font-face {
    
    font-family: ltr-alternativePrimary;
    src: url('../fonts/rtl_primary.ttf')  format('truetype');
}
@font-face
{
    
    font-family: ltr-alternativeSecondary;
    src: url('../fonts/rtl_secondary.ttf')  format('truetype');
}
.ltr-primaryFont
{
    
    font-family: ltr-primary !important;
    line-height: 100%;
}
.ltr-secondaryFont
{
    
    font-family: ltr-secondary !important;
    line-height: 100%;
}
.ltr-alternativePrimaryFont
{
    
    font-family: ltr-alternativePrimary !important;
    line-height: 100%;
}
.ltr-alternativeSecondaryFont
{
    
    font-family: ltr-alternativeSecondary !important;
    line-height: 120%;
}

/* DIRECTION AND ALIGNMENT */
.ltr
{
    
    direction: ltr;
    text-align: left;
    font-family: ltr-secondary;
}
.ltr-reverse-direction
{
    direction: rtl !important;
    text-align: right;
}
.ltr-pull-to-leading
{
    text-align: left !important;
}
.ltr-pull-to-trailing
{
    text-align: right !important;
}
.ltr-float-to-leading
{
    float: left;
}
.ltr-float-to-trailing
{
    float: right;
}
.ltr-fix-to-leading
{
    left: 0;
}
.ltr-fix-to-trailing
{
    right: 0;
}

/* SHADOW */
.ltr-shadow-leading
{
    -webkit-box-shadow: -30px 0px 30px -15px rgba(203,205,208,0.5);
    -moz-box-shadow: -30px 0px 30px -15px rgba(203,205,208,0.5);
    box-shadow: -30px 0px 30px -15px rgba(203,205,208,0.5);
}
.ltr-shadow-trailing
{
    -webkit-box-shadow: 30px 0px 30px -15px rgba(203,205,208,0.5);
    -moz-box-shadow: 30px 0px 30px -15px rgba(203,205,208,0.5);
    box-shadow: 30px 0px 30px -15px rgba(203,205,208,0.5);
}

/* ROUNDED CORNERS */
.ltr-rounded-top-leading
{
    -webkit-border-top-left-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-topleft: $size-rounded-corner-sm !important;
	-moz-border-radius-topleft: $size-rounded-corner-sm !important;
	border-top-left-radius: $size-rounded-corner-sm !important;
}
.ltr-rounded-top-trailing
{
    -webkit-border-top-right-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-topright: $size-rounded-corner-sm !important;
	-moz-border-radius-topright: $size-rounded-corner-sm !important;
	border-top-right-radius: $size-rounded-corner-sm !important;
}
.ltr-rounded-bottom-leading
{
    -webkit-border-bottom-left-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-bottomleft: $size-rounded-corner-sm !important;
	-moz-border-radius-bottomleft: $size-rounded-corner-sm !important;
	border-bottom-left-radius: $size-rounded-corner-sm !important;
}
.ltr-rounded-bottom-trailing
{
    -webkit-border-bottom-right-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-bottomright: $size-rounded-corner-sm !important;
	-moz-border-radius-bottomright: $size-rounded-corner-sm !important;
	border-bottom-right-radius: $size-rounded-corner-sm !important;
}
.ltr-rounded-top
{
    -webkit-border-top-left-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-topleft: $size-rounded-corner-sm !important;
	-moz-border-radius-topleft: $size-rounded-corner-sm !important;
	border-top-left-radius: $size-rounded-corner-sm !important;

    -webkit-border-top-right-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-topright: $size-rounded-corner-sm !important;
	-moz-border-radius-topright: $size-rounded-corner-sm !important;
	border-top-right-radius: $size-rounded-corner-sm !important;
}
.ltr-rounded-bottom
{
    -webkit-border-bottom-left-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-bottomleft: $size-rounded-corner-sm !important;
	-moz-border-radius-bottomleft: $size-rounded-corner-sm !important;
	border-bottom-left-radius: $size-rounded-corner-sm !important;

    -webkit-border-bottom-right-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-bottomright: $size-rounded-corner-sm !important;
	-moz-border-radius-bottomright: $size-rounded-corner-sm !important;
	border-bottom-right-radius: $size-rounded-corner-sm !important;
}
.ltr-rounded-leading
{
    -webkit-border-top-left-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-topleft: $size-rounded-corner-sm !important;
	-moz-border-radius-topleft: $size-rounded-corner-sm !important;
    border-top-left-radius: $size-rounded-corner-sm !important;

    -webkit-border-bottom-left-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-bottomleft: $size-rounded-corner-sm !important;
	-moz-border-radius-bottomleft: $size-rounded-corner-sm !important;
	border-bottom-left-radius: $size-rounded-corner-sm !important;
}
.ltr-rounded-lg-leading
{
    -webkit-border-top-left-radius: $size-rounded-corner-lg !important;
	-khtml-border-radius-topleft: $size-rounded-corner-lg !important;
	-moz-border-radius-topleft: $size-rounded-corner-lg !important;
    border-top-left-radius: $size-rounded-corner-lg !important;

    -webkit-border-bottom-left-radius: $size-rounded-corner-lg !important;
	-khtml-border-radius-bottomleft: $size-rounded-corner-lg !important;
	-moz-border-radius-bottomleft: $size-rounded-corner-lg !important;
	border-bottom-left-radius: $size-rounded-corner-lg !important;
}
.ltr-rounded-trailing
{
    -webkit-border-top-right-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-topright: $size-rounded-corner-sm !important;
	-moz-border-radius-topright: $size-rounded-corner-sm !important;
    border-top-right-radius: $size-rounded-corner-sm !important;

    -webkit-border-bottom-right-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-bottomright: $size-rounded-corner-sm !important;
	-moz-border-radius-bottomright: $size-rounded-corner-sm !important;
	border-bottom-right-radius: $size-rounded-corner-sm !important;
}

.ltr-rounded-lg-trailing
{
    -webkit-border-top-right-radius: $size-rounded-corner-lg !important;
	-khtml-border-radius-topright: $size-rounded-corner-lg !important;
	-moz-border-radius-topright: $size-rounded-corner-lg !important;
    border-top-right-radius: $size-rounded-corner-lg !important;

    -webkit-border-bottom-right-radius: $size-rounded-corner-lg !important;
	-khtml-border-radius-bottomright: $size-rounded-corner-lg !important;
	-moz-border-radius-bottomright: $size-rounded-corner-lg !important;
	border-bottom-right-radius: $size-rounded-corner-lg !important;
}

/* CUSTOM react-notifications-component style */
.notification-container--top-right > .notification > .animate__animated > .notification__content > .notification__title
{
    font-family: ltr-primary !important;
}

.notification-container--top-right > .notification > .animate__animated > .notification__content > .notification__message
{
    font-family: ltr-secondary !important;
}

.notification-container--top-right > .notification >  .notification__item--success
{
	background-color: $color-light-green !important;
    border-left: $size-rounded-corner-sm solid #01a375 !important;
	border-radius: $size-rounded-corner-sm !important;
}

.notification-container--top-right > .notification >  .notification__item--warning
{
	border-radius: $size-rounded-corner-sm !important;
}

.notification-container--top-right > .notification > .notification__item--danger
{
	background-color: $color-rose !important;
    border-left: $size-rounded-corner-sm solid #e03053 !important;
	border-radius: $size-rounded-corner-sm !important;
}