@import "../../../assets/css/config.scss";

.listView-mainContainer
{
    position: relative;
}

.listView-headerContainer
{
    position: absolute;
    top: 0;
    height: 100px;
    z-index: 10;
}

.listView-scrollingArea
{
    padding-top: 110px;
    padding-bottom: 110px;
}

.listView-footerContainer
{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 65px;
}

thead th { position: sticky; top: 0; }

@media (max-width: ($breakpoint-sm - 1))
{
    .listView-headerContainer
    {
        position: relative;
        top: 0;
        height: 90px;
    }

    .listView-scrollingArea
    {
        height: 100%;
        padding-top: 70px;
        padding-bottom: 500px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}