@import "../../../assets/css/config.scss";


.productCheckoutView-dot 
{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
}

.productCheckoutView-dot-border
{
    border-width: 10px;
    border-style: solid;
    border-color: red !important;
}

