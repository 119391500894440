@import "../../../assets/css/config.scss";

@media (min-width: $breakpoint-xl)
{
    .loginView-col
	{
		min-width: 480px !important;
		max-width: 480px !important;
	}
}

@media (max-width: ($breakpoint-xl - 1))
{
    .loginView-col
	{
		min-width: 480px !important;
		max-width: 480px !important;
	}
}

@media (max-width: ($breakpoint-lg - 1))
{
    .loginView-col
	{
		min-width: 450px !important;
		max-width: 450px !important;
	}
}

@media (max-width: ($breakpoint-md - 1))
{
    .loginView-col
	{
		min-width: 400px !important;
		max-width: 400px !important;
	}
}

@media (max-width: ($breakpoint-sm - 1))
{
    .loginView-col
	{
		min-width: 400px !important;
		max-width: 400px !important;
	}
}

@media (max-width: ($breakpoint-xs - 1))
{
	.loginView-col
	{
		min-width: 90% !important;
		max-width: 90% !important;
	}
}

