@import "config";

/* FONTS */
@font-face
{
    font-family: rtl-primary;
    src: url('../fonts/rtl_primary.ttf')  format('truetype');
}
@font-face
{
    font-family: rtl-secondary;
    src: url('../fonts/rtl_secondary.ttf')  format('truetype');
}
@font-face {
    font-family: rtl-alternativePrimary;
    src: url('../fonts/ltr_primary.ttf')  format('truetype');
}
@font-face
{
    font-family: rtl-alternativeSecondary;
    src: url('../fonts/ltr_secondary.ttf')  format('truetype');
}
.rtl-primaryFont
{
    font-family: rtl-primary !important;
    line-height: 100%;
}
.rtl-secondaryFont
{
    font-family: rtl-secondary !important;
    line-height: 100%;
}
.rtl-alternativePrimaryFont
{
    font-family: rtl-alternativePrimary !important;
    line-height: 100%;
}
.rtl-alternativeSecondaryFont
{
    font-family: rtl-alternativeSecondary !important;
    line-height: 120%;
}

/* DIRECTION AND ALIGNMENT */
.rtl
{
    direction: rtl;
    text-align: right;
    font-family: rtl-secondary;
}
.rtl-reverse-direction
{
    direction: ltr !important;
    text-align: left;
}
.rtl-pull-to-leading
{
    text-align: right !important;
}
.rtl-pull-to-trailing
{
    text-align: left !important;
}
.rtl-float-to-leading
{
    float: right;
}
.rtl-float-to-trailing
{
    float: left;
}
.rtl-fix-to-leading
{
    right: 0;
}
.rtl-fix-to-trailing
{
    left: 0;
}

/* SHADOW */
.rtl-shadow-leading
{
    -webkit-box-shadow: 30px 0px 30px -15px rgba(203,205,208,0.5);
    -moz-box-shadow: 30px 0px 30px -15px rgba(203,205,208,0.5);
    box-shadow: 30px 0px 30px -15px rgba(203,205,208,0.5);
}
.rtl-shadow-trailing
{
    -webkit-box-shadow: -30px 0px 30px -15px rgba(203,205,208,0.5);
    -moz-box-shadow: -30px 0px 30px -15px rgba(203,205,208,0.5);
    box-shadow: -30px 0px 30px -15px rgba(203,205,208,0.5);
}

/* ROUNDED CORNERS */
.rtl-rounded-top-leading
{
    -webkit-border-top-right-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-topright: $size-rounded-corner-sm !important;
	-moz-border-radius-topright: $size-rounded-corner-sm !important;
	border-top-right-radius: $size-rounded-corner-sm !important;
}
.rtl-rounded-top-trailing
{
    -webkit-border-top-left-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-topleft: $size-rounded-corner-sm !important;
	-moz-border-radius-topleft: $size-rounded-corner-sm !important;
	border-top-left-radius: $size-rounded-corner-sm !important;
}
.rtl-rounded-bottom-leading
{
    -webkit-border-bottom-right-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-bottomright: $size-rounded-corner-sm !important;
	-moz-border-radius-bottomright: $size-rounded-corner-sm !important;
	border-bottom-right-radius: $size-rounded-corner-sm !important;
}
.rtl-rounded-bottom-trailing
{
    -webkit-border-bottom-left-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-bottomleft: $size-rounded-corner-sm !important;
	-moz-border-radius-bottomleft: $size-rounded-corner-sm !important;
	border-bottom-left-radius: $size-rounded-corner-sm !important;
}
.rtl-rounded-top
{
    -webkit-border-top-right-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-topright: $size-rounded-corner-sm !important;
	-moz-border-radius-topright: $size-rounded-corner-sm !important;
	border-top-right-radius: $size-rounded-corner-sm !important;

    -webkit-border-top-left-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-topleft: $size-rounded-corner-sm !important;
	-moz-border-radius-topleft: $size-rounded-corner-sm !important;
	border-top-left-radius: $size-rounded-corner-sm !important;
}
.rtl-rounded-bottom
{
    -webkit-border-bottom-right-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-bottomright: $size-rounded-corner-sm !important;
	-moz-border-radius-bottomright: $size-rounded-corner-sm !important;
	border-bottom-right-radius: $size-rounded-corner-sm !important;

    -webkit-border-bottom-left-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-bottomleft: $size-rounded-corner-sm !important;
	-moz-border-radius-bottomleft: $size-rounded-corner-sm !important;
	border-bottom-left-radius: $size-rounded-corner-sm !important;
}
.rtl-rounded-leading
{
    -webkit-border-top-right-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-topright: $size-rounded-corner-sm !important;
	-moz-border-radius-topright: $size-rounded-corner-sm !important;
    border-top-right-radius: $size-rounded-corner-sm !important;

    -webkit-border-bottom-right-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-bottomright: $size-rounded-corner-sm !important;
	-moz-border-radius-bottomright: $size-rounded-corner-sm !important;
	border-bottom-right-radius: $size-rounded-corner-sm !important;
}
.rtl-rounded-lg-leading
{
    -webkit-border-top-right-radius: $size-rounded-corner-lg !important;
	-khtml-border-radius-topright: $size-rounded-corner-lg !important;
	-moz-border-radius-topright: $size-rounded-corner-lg !important;
    border-top-right-radius: $size-rounded-corner-lg !important;

    -webkit-border-bottom-right-radius: $size-rounded-corner-lg !important;
	-khtml-border-radius-bottomright: $size-rounded-corner-lg !important;
	-moz-border-radius-bottomright: $size-rounded-corner-lg !important;
	border-bottom-right-radius: $size-rounded-corner-lg !important;
}
.rtl-rounded-trailing
{
    -webkit-border-top-left-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-topleft: $size-rounded-corner-sm !important;
	-moz-border-radius-topleft: $size-rounded-corner-sm !important;
    border-top-left-radius: $size-rounded-corner-sm !important;

    -webkit-border-bottom-left-radius: $size-rounded-corner-sm !important;
	-khtml-border-radius-bottomleft: $size-rounded-corner-sm !important;
	-moz-border-radius-bottomleft: $size-rounded-corner-sm !important;
	border-bottom-left-radius: $size-rounded-corner-sm !important;
}

.rtl-rounded-lg-trailing
{
    -webkit-border-top-left-radius: $size-rounded-corner-lg !important;
	-khtml-border-radius-topleft: $size-rounded-corner-lg !important;
	-moz-border-radius-topleft: $size-rounded-corner-lg !important;
    border-top-left-radius: $size-rounded-corner-lg !important;

    -webkit-border-bottom-left-radius: $size-rounded-corner-lg !important;
	-khtml-border-radius-bottomleft: $size-rounded-corner-lg !important;
	-moz-border-radius-bottomleft: $size-rounded-corner-lg !important;
	border-bottom-left-radius: $size-rounded-corner-lg !important;
}

/* CUSTOM react-notifications-component style */
.notification-container--top-left > .notification > .animate__animated > .notification__content > .notification__title
{
    font-family: rtl-primary !important;
    text-align: right !important;
}

.notification-container--top-left > .notification > .animate__animated > .notification__content > .notification__message
{
    font-family: rtl-secondary !important;
    text-align: right !important;
}

.notification-container--top-left > .notification >  .notification__item--success
{
	background-color: $color-light-green !important;
    border-right: $size-rounded-corner-sm solid #01a375 !important;
    border-left: unset !important;
	border-radius: $size-rounded-corner-sm !important;
}

.notification-container--top-right > .notification >  .notification__item--warning
{
    border-left: unset !important;
	border-radius: $size-rounded-corner-sm !important;
}

.notification-container--top-left > .notification > .notification__item--danger
{
	background-color: $color-rose !important;
    border-right: $size-rounded-corner-sm solid #e03053 !important;
    border-left: unset !important;
	border-radius: $size-rounded-corner-sm !important;
}