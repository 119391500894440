@import "../../../assets/css/config.scss";

.optView-otp-input-container
{
    width: 12% !important;
}

.optView-otp-input
{
    text-align: center !important;
    font-size: $size-text-lg;
    font-weight: 900;
}
.otpView-container-width{
    width:40% !important
}