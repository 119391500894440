@import "../../../assets/css/config.scss";

.manageUserView-user-avatar
{
    height: 70px;
    width: 70px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    border-radius: 50%;
}

.ltr-manageUserView-item-container
{
    border-left-width: 1px;
    border-left-style: solid;   
    padding-left: 3rem!important; 
}

.rtl-manageUserView-item-container
{
    border-right-width: 1px;
    border-right-style: solid;
    padding-right: 3rem!important;
}

.manageUserView-notVerified
{
    color: $color-secondary-highlight !important;
}

.manageUserView-verified
{
    color: $color-success !important;
}

.rtl-manageUserView-username
{
    direction: ltr !important;
}

.tournamentDetailsView-game-image
{
    height: 181px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
}



@media (max-width: ($breakpoint-xs - 1))
{
    .ltr-manageUserView-item-container
    {
        border: none !important;    
        padding: 0 !important;
    }

    .rtl-manageUserView-item-container
    {
        border: none !important;
        padding: 0 !important;
    }

}

.manageUserView-border
{
    border-width: 1px !important;
    border-style: solid;
    border-color: $color-rose !important;
    width: 40px !important;
    height: 38px !important;
    padding: 12px 15px 16px 15px;
}